<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-11-11 09:41:02
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-11-14 10:27:23
-->
<template>
    <div class="tutorialsList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">视频教程</div>
        </div>
        <div class="case-wrap">
            <div class="content-wrap">
                <div class="cw-box-item ">
                    <div class="b-box">
                        <div class="b-box-tit">
                            <div class="tag"></div>
                            <div class="title">控制台</div>
                        </div>
                        <div class="b-box-con">
                            <div :class="title==item.name?'active':''" class="b-con-item" @click="clickWorkbench(item.name)" v-for="(item,index) in workbench" :key="index">
                                <img class="image" :src="item.img" mode="" />
                                <div class="text">{{item.name}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="b-box" v-if="permit.mcenter">
                        <div class="b-box-tit">
                            <div class="tag"></div>
                            <div class="title">团队管理</div>
                        </div>
                        <div class="b-box-con">
                            <div :class="title==item.name?'active':''" class="b-con-item" @click="clickWorkbench(item.name)" v-for="(item,index) in teamList" :key="index">
                                <img class="image" :src="item.img" mode="" />
                                <div class="text">{{item.name}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="b-box" v-if="permit.mcenter">
                        <div class="b-box-tit">
                            <div class="tag"></div>
                            <div class="title">活动管理</div>
                        </div>
                        <div class="b-box-con">
                            <div :class="title==item.name?'active':''" class="b-con-item" @click="clickWorkbench(item.name)" v-for="(item,index) in activityList" :key="index">
                                <img class="image" :src="item.img" mode="" />
                                <div class="text">{{item.name}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="b-box" v-if="permit.mcenter">
                        <div class="b-box-tit">
                            <div class="tag"></div>
                            <div class="title">云店管理</div>
                        </div>
                        <div class="b-box-con">
                            <div :class="title==item.name?'active':''" class="b-con-item" @click="clickWorkbench(item.name)" v-for="(item,index) in storeList" :key="index">
                                <img class="image" :src="item.img" mode="" />
                                <div class="text">{{item.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="video-wrap">
                <div class="vw-title">{{title}}</div>
                <div class="vw-video" v-loading="loading">
                    <video class="video" controls :autoplay="true"
                        :src="video_url">
                    </video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
export default {
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'tutorialsList',
            workbench: [{
                img: require('../../assets/icon/moment.png'),
                name: '朋友圈海报'
            },{
                img: require('../../assets/icon/product_share.png'),
                name: '产品中心'
            },{
                img: require('../../assets/icon/activity.png'),
                name: '活动中心'
            },{
                img: require('../../assets/icon/3D.png'),
                name: '全景设计'
            },{
                img: require('../../assets/icon/calculator.png'),
                name: '快速报价'
            },{
                img: require('../../assets/icon/remind.png'),
                name: '备忘提醒'
            },{
                img: require('../../assets/icon/record.png'),
                name: '客户档案'
            },{
                img: require('../../assets/icon/shared_circle.png'),
                name: '共享圈'
            },{
                img: require('../../assets/icon/pc-login.png'),
                name: '电脑端'
            }],
            teamList:[{
                img: require('../../assets/manage/mubiaoguanli.png'),
                name: '目标管理'
            },{
                img: require('../../assets/manage/kehuqianyi.png'),
                name: '客户管理'
            },{
                img: require('../../assets/manage/xiaoshoushuju.png'),
                name: '团队数据'
            },{
                img: require('../../assets/manage/zhanghaoguanli.png'),
                name: '账号管理'
            },],
            activityList:[{
                img: require('../../assets/manage/pinpaihuodong.png'),
                name: '品牌活动'
            },{
                img: require('../../assets/manage/chuangjianhuodong.png'),
                name: '方案管理'
            },{
                img: require('../../assets/manage/dinjinguanli.png'),
                name: '定金管理'
            },{
                img: require('../../assets/manage/huodongtongji.png'),
                name: '活动数据'
            }],
            storeList:[{
                img: require('../../assets/manage/mendianxinxi.png'),
                name: '云店设置'
            },{
                img: require('../../assets/manage/chanpinbianji.png'),
                name: '产品管理'
            },{
                img: require('../../assets/manage/koubeishenhe.png'),
                name: '口碑管理'
            },],
            permit: {},
            title: '视频教程',
            video_url: '',
            loading: false
        }
    },
    mounted () {
        this.permit = JSON.parse(localStorage.getItem("permission"));
    },
    methods: {
        clickWorkbench(item){
            this.title = item;
            let params = {
                keyword: this.title
            }
            this.video_url = ''
            this.loading = true;
            common.connect('/distributorpcv1/shop_tutorial_video/show',params,(res)=>{
                if(res.data){
                    this.video_url = res.data.video_url;
                    this.loading = false;
                }else{
                    this.$message.error('暂无'+this.title+'的视频教程!');
                    this.loading = false;
                }
            })
        }
    },
}
</script>

<style lang='scss'>
.tutorialsList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        height: calc(100% - 60px);
        background: #fff;
        border-radius: 6px;
        margin: 15px 0;
        display: flex;
        justify-content: space-between;
        .content-wrap{
            height: 100%;
            width: calc(100% - 400px);
            .cw-box-item{
                padding: 20px 0;
                .b-box{
                    margin-top: 8px;
                    .b-box-tit{
                        padding: 10px 30px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        .tag{
                            width: 3px;
                            height: 15px;
                            background: #409EFF;
                            border-radius: 10px;
                        }
                        .title{
                            margin-left: 5px;
                            font-size: 18px;
                            font-weight: bold;
                            color: #000;
                        }
                    }
                    .active{
                        border-radius: 10px;
                        border: 1px solid #ddd;
                    }
                    .b-box-con{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        background: #fff;
                        border-radius: 10px;
                        padding: 10px;
                        .b-con-item{
                            width: 110px;
                            text-align: center;
                            padding: 5px 0;
                            cursor: pointer;
                            .image{
                                width: 70px;
                                height: 70px;
                            }
                            .text {
                                font-size: 16px;
                                color: #666;
                                padding-top: 5px;
                            }
                        }
                    }
                }
            }
        }
        .video-wrap{
            width: 400px;
            height: 100%;
            border: 1px solid #ddd;
            display: flex;
            flex-direction: column;
            align-items: center;
            .vw-title{
                width: 100%;
                height: 40px;
                line-height: 40px;
                font-size: 18px;
                color: #333;
                text-align: center;
                background: #f3f3f3;
            }
            .vw-video{
                height: calc(100% - 40px);
                display: flex;
                justify-content: center;
                align-items: center;
                .video{
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}
</style>
